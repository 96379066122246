<template>
  <div>
    <header id="header">
      <h1><img src="/asset/img/common/headerlogo.svg" alt="PLUG キャラクター売上予測AI"></h1>
      <ul class="subnavi">
        <li v-if="routeName === 'Result'">
          <a @click="goToSelectMenu"><i class="fa fa-sign-out pr5" />メニュー選択</a>
        </li>
        <li>
          <a @click="logout">
            <i class="fa fa-sign-out pr5" />ログアウト
          </a>
        </li>
      </ul>
    </header>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { actions as actionsUser } from '../store/modules/user'

export default {
  name: 'UserLayout',
  data() {
    return {
      routeName: ''
    }
  },
  computed: {
    isLogin() {
      return this.$store.getters.userInfo.id
    }
  },
  watch: {
    $route() {
      this.routeName = this.$route.name
    }
  },
  methods: {
    logout() {
      this.$store.dispatch(actionsUser.LOGOUT)
      this.$router.push({ name: 'login' })
    },
    goToSelectMenu() {
      window.location.href = '/project/select-menu'
    }
  }
}
</script>

<style lang="scss">
.subnavi {
  li {
    a {
      cursor: pointer;
    }
  }
}
</style>
